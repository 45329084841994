<template>
    <div class="all" :style="{backgroundImage: 'url(' + bg + ')'}">
        <div class="zhanwei"></div>
        <div class="all-main">
            <div class="all-main-left">
                <!-- <div class="all-main-li"></div> -->
                <div class="all-main-li-zhanwei"></div>
                <div @click="cutClickStatus(0)" :class="[status==0?'all-avater':'']" class="all-main-li">反馈信息</div>
                <div class="all-main-li-zhanwei"></div>
                <div  @click="cutClickStatus(1)" :class="[status==0?'':'all-avater']" class="all-main-li">收件地址</div>
                <div class="all-main-li-zhanwei"></div>
            </div>
            <div class="my-main">
                <!-- 反馈内容 -->
                <div v-if="status == 0">
                    <div class="all-main-titlr-txt">反馈内容</div>
                    <el-input
                    type="textarea"
                    v-model="content"
                    placeholder="请输入内容"
                    >
                    </el-input>
                    <div class="tijiao-button-wai">
                        <el-button :loading="buttonStatus" @click="feedbackClick" class="tijiao-button">提交反馈</el-button>
                    </div>
                </div>

                <!-- 收件地址 -->
                <div v-if="status == 1">
                    <div v-show="AddressStatus == 0" class="all-title-address-all">
                        <div class="all-main-txex">空运中转仓库地址</div>
                    </div>
                    <div v-show="AddressStatus == 0" class="all-list">姓名：{{haiyun.shr}}</div>
                    <div v-show="AddressStatus == 0" class="all-list">联系方式：{{haiyun.phone}}</div> 
                    <div v-show="AddressStatus == 0" class="all-list">收件地址：{{haiyun.address}}</div> 
                    <div style="height:20px"></div>
                    <div  class="all-title-address-all">
                        <div class="all-main-txex">{{AddressStatus != 0 ?AddressTxt:'海运中转仓库地址'}}</div>
                        <div v-show="AddressStatus != 0" @click="EditAddressClick" class="all-main-txex-quxiao">取消</div>
                    </div>

                    <div v-show="AddressStatus == 0">
                        <div class="all-list">姓名：{{defaultAddress.shr}}</div>
                        <div class="all-list">联系方式：{{defaultAddress.phone}}</div> 
                        <div class="all-list">收件地址：{{defaultAddress.address}}</div> 

                        <div class="all-flex" style="visibility: hidden;">
                            <div class="all-shoujian">收件地址</div>
                            <!-- <div @click="addAddressClick" class="all-add">
                                <div class="el-icon-plus"></div>
                                <div>新增收件地址</div>
                            </div> -->
                        </div>

                        <!-- <div v-for="(item,key) in AddressList" :key="key" class="dialog-all">
                            <el-image class="buying-main-image" :src="require('@/assets/image/address1.png')"></el-image>
                            <div class="address-main-all">
                                <div class="dialog-title">
                                    <div class="buying-title">
                                        <div class="dialog-name">{{item.name}}</div>
                                        <div class="dialog-phone">{{item.mobile}}</div>
                                    </div>
                                    <div class="buying-title button-all-my">
                                        <div @click="editClick(item)" class="dialog-button dialog-button-lan">编辑</div>
                                        <div @click="detailClick(item)" class="dialog-button dialog-button-hongse">删除</div>
                                    </div> 
                                </div>
                                <div>{{item.guojia}}{{item.sheng}}{{item.shi}}{{item.qu}}{{item.address}}</div>
                            </div>
                        </div> -->
                    </div>

                    <div v-show="AddressStatus != 0">
                        <div class="all-list">姓名：<input placeholder="请输入姓名" v-model="form.name" /></div>
                        <div class="all-list">联系方式：<input placeholder="请输入联系方式" v-model="form.mobile" /></div>
                        <div class="all-list">国家：<input placeholder="请输入国家" v-model="form.guojia" /></div>
                        <div class="all-list">城市/区域：<input placeholder="请输入城市/区域" v-model="form.sheng" /></div>
                        <div class="all-list">省/州/郡：<input placeholder="请输入省/州/郡" v-model="form.shi" /></div>
                        <!-- <div class="all-list">收件地址：<input placeholder="请输入联系方式" v-model="form.mobile" /> -->
                            <!-- <el-cascader v-show="AddressStatus != 0" class="casecard-all" ref="cascader" v-model="sanjiAddress" placeholder="请选择收货地址" @change="handleChanges" :props="props"></el-cascader> -->
                        <!-- </div> -->
                        <div class="all-list">详细地址：<input placeholder="请输入详细地址" v-model="form.address" /></div>
                        <div class="all-list">邮编：<input placeholder="请输入邮编" v-model="form.youbian" /></div>
                        <el-button class="tijiao-button baocun-button"  @click="saveButtonClick" :loading="buttonStatus" v-show="AddressStatus">保存</el-button>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {feedback,
        area,
        settings,
        showaddress,
        addaddress,
        editaddress,
        deladdress,
        worldarea,
        getset
} from '@/api/index'
export default {
    name:'My',
    data(){
        return{
            status:1,
            // 0 正常  1添加收货地址  2编辑收货地址
            AddressStatus:0,
            AddressTxt:'编辑收货地址',
            content:'',
            form:{
                name:'你好',
                mobile:'157129284',
                sheng:'',
                shi:'',
                qu:'',
                address:'这是一个详细地址详细地址',
            },
            haiyun:{},
            buttonStatus:false,// button状态
            // props: {
            //     lazy: true,
            //     lazyLoad (node, resolve) {
            //         let pid = node.data == undefined ? 0 : node.data.id
            //         area({pid:pid}).then(res=>{
            //             let data = res.data
            //             const nodes = data.map(item => ({
            //                 id:item.id,
            //                 value:item.name,
            //                 leaf: item.level > 2,
            //                 level: item.level,
            //                 label: item.name
            //             }));
            //             resolve(nodes);
            //         })
            //     }
            // },// 联动配置
            
            sanjiAddress:[],//三级联动默认值
            AddressList:[],//收货地址
            defaultAddress:{},// 获取默认地址
            bg:'',
        }
    },
    computed:{
        addressDetail:function(){
            return this.form.sheng +  this.form.shi + this.form.qu
        } 
    },
    created(){
        getset({name:'my_bg'}).then(res=>{this.bg = this.$store.state.baseUrl + res.data})
        this.getAddressList()
        this.getsettings()
    },
    methods:{
        // 获取国内中转仓库地址
        getsettings(){
            settings({type:1}).then(res=>{
                this.defaultAddress = res.data
            })
            settings({type:2}).then(res=>{
                this.haiyun = res.data
            })
        },
        // 编辑
        editClick(row){
            this.form = row
            this.AddressStatus = 2
            // this.sanjiAddress = []
            // this.sanjiAddress.push(row.sheng,row.shi,row.qu)
            // this.$refs.cascader.panel.lazyLoad();
            // console.log(this.sanjiAddress)
        },
        // 删除
        detailClick(row){
            this.$confirm('确定要删除该地址吗?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
                }).then(() => {
                    deladdress({id:row.id}).then(res=>{
                        if(res.code){
                            // 确定删除
                            this.$message({
                                type: 'success',
                                message: '删除成功!'
                            });
                            this.getAddressList();
                        }
                    })
                    
                }).catch(() => {         
            });
        },
        // 获取收货地址
        getAddressList(){
            showaddress().then(res=>{
                this.AddressList = res.data
            })
        },
        // 提交反馈
        feedbackClick(){
            if(!this.content){
                this.$message({
                    type:'success',
                    messgae:'请输入内容'
                })
                return
            }
            this.buttonStatus = true
            feedback({content:this.content}).then(res=>{
                this.$message({
                    type:'success',
                    message:res.msg
                })
                this.buttonStatus = false
                this.content = ''
            })
        },
        CutStatus(){

        },
        // 省市区列表选中触发
        handleChanges(item){
            this.form.sheng = item[0]
            this.form.shi = item[1]
            this.form.qu = item[2]
        },
        // 保存收货地址
        saveButtonClick(){
            if(this.AddressStatus == 1){
                // 添加收货地址
                this.buttonStatus = true
                addaddress(this.form).then(res=>{
                    this.$message({
                        type:'success',
                        message:res.msg
                    })
                    this.AddressStatus = 0
                    this.buttonStatus = false
                    this.getAddressList()
                })
            }
            if(this.AddressStatus == 2){
                this.buttonStatus = true
                editaddress(this.form).then(res=>{
                    this.$message({
                        type:'success',
                        message:res.msg
                    })
                    this.AddressStatus = 0
                    this.buttonStatus = false
                    this.getAddressList()
                })
            }
        },
        // 切换标签
        cutClickStatus(id){
            this.status = id
        },
        // 添加/保存 收货地址
        addAddressClick(){
            this.AddressStatus = 1
            this.AddressTxt = '添加收货地址',
            this.form = {}

        },
        // 取消收货地址
        EditAddressClick(){
            this.AddressStatus = 0
            this.AddressTxt = '国内中转仓库地址'
        }
    }
}
</script>


<style>
.el-textarea__inner{
    min-height: 356px !important;
    overflow-y: auto;
    /* overflow-y: auto;兼容ie  */
 }
 .el-input__inner{
    border:none
 }
</style>
<style scoped>
::-webkit-input-placeholder { color:#BBBBBB; }
::-moz-placeholder { color:#BBBBBB; } /* firefox 19+ */
:-ms-input-placeholder { color:#BBBBBB; } /* ie */
input:-moz-placeholder { color:#BBBBBB; }
input:focus{
	outline: medium;
}
input{
    border: none;   
    font-size: 20px;
    color: #23377C;
    width: 80%;
}
.all{
    background-attachment:fixed;
    background-repeat: no-repeat;
    background-size: cover;
}
.all-main{
    width:70%;
    margin: auto;
    padding-bottom: 40px;
    margin-top: 44px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}
.zhanwei{
    height: 120px;
}
.all-main-left{
    width: 300px;
    color: #ffffff;
    background: #23377C;
    font-size: 20px;
}
.all-main-li-zhanwei{
    height: 20px;
}
.all-main-li{
    height: 60px;
    line-height: 60px;
    text-align: center;
    cursor: pointer;
}
.all-avater{
    background: #ffffff;
    color: #23377C;
}
.my-main{
    margin-left: 20px;
    width: 980px;
    background: #ffffff;
    padding: 60px 110px 120px 110px;
}
.all-main-titlr-txt{
    margin-bottom: 30px;
}
.tijiao-button{
    width: 620px;
    height: 90px;
    background: #5E89FF;
    box-shadow: 0px 9px 18px 0px rgba(94, 137, 255, 0.18);
    border-radius: 45px;
    font-size: 30px;
    color: #FFFFFF;
    margin: auto;
}
.tijiao-button-wai{
    margin-top: 90px;
    text-align: center;
}
.text-all{
    height: 356px;
}
.all-main-txex{
    width: 218px;
    line-height: 61px;
    text-align: center;
    height: 61px;
    background: #23377C;
    border-radius: 3px;
    font-size: 24px;
    color: #FFFFFF;
}
.all-list{
    display: flex;
    align-items: center;
    height: 70px;
    border: 2px solid #E8E8E8;
    font-size: 20px;
    font-weight: 500;
    color: #23377C;
    margin-top: 20px;
    padding-left: 20px
}
.all-shoujian{
    font-size: 24px;
    color: #FFFFFF;
    text-align: center;
    width: 123px;
    height: 60px;
    line-height: 60px;
    background: #F6AF16;
    border-radius: 3px;
}
.all-add{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 231px;
    height: 61px;
    background: #7694FB;
    border-radius: 4px;
    font-size: 24px;
    color: #FFFFFF;
    cursor: pointer;
}
.all-flex{
    margin: 50px 0 40px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dialog-all{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 27px;
    height: 185px;
    background: #F5F5F5;
    border-radius: 10px;
    margin-bottom:20px;
    font-size: 20px;
    font-weight: 500;
    color: #333333;
}
.buying-main-image{
    width: 54px;
    height: 54px;
    margin-right: 30px;
}
.dialog-title{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom:23px;
}
.dialog-button{
    width: 66px;
    height: 28px;
    line-height: 28px;
    text-align: center;
    border: 1px solid #F6AF16;
    border-radius: 8px;
    font-size: 18px;
    color: #F6AF16;
    margin-right: 20px;
    cursor: pointer;
}
.dialog-button-lan{
    border: 1px solid #569ae9;
    color: #569ae9;
}
.dialog-button-hongse{
    border: 1px solid #FF521E;
    color: #FF521E;
    margin-right: 0;
}
.dialog-name{
    font-size: 29px;
    font-weight: 500;
    color: #333333;
    margin-right:23px;
}
.dialog-phone{    
    font-size: 25px;
    font-weight: 500;
    color: #333333;
}
.buying-title{
    display: flex;
    align-items: center;
}
.all-title-address-all{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.all-main-txex-quxiao{
    width: 89px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    background: #CECECE;
    border-radius: 3px;
    color: #FFFFFF;
    font-size: 24px;
    cursor: pointer;
}
.baocun-button{
    width: 100%;
    margin-top: 80px;
}
.address-main-all{
    /* min-width: 600px; */
}
.casecard-all{
    width: 80%;
}
.button-all-my{
    margin-left: 50px;
}
</style>